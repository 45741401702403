import { combineReducers, Reducer } from 'redux'
import { getType } from 'typesafe-actions'

import { AppAction } from '../../app.actions'
import { SettingsState } from './settings.types'
import * as Actions from './settings.actions'

// TODO: Build a settings window to allow changing currency
const currencyReducer: Reducer<SettingsState['currency'], AppAction> = (state = 'PLN', action) => {
  switch (action.type) {
    default:
      return state
  }
}

// TODO: Build a settings window to allow changing irregular divisor
// TODO: Remember to recalculate irregular values
const irregularDivisorReducer: Reducer<SettingsState['irregularDivisor'], AppAction> = (state = 10, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const irregularTypeReducer: Reducer<SettingsState['irregularType'], AppAction> = (state = 'irregular', action) => {
  switch (action.type) {
    case getType(Actions.loadBudgetSettings):
      return action.payload.irregularType
    default:
      return state
  }
}

export const reducer = combineReducers({
  currency: currencyReducer,
  irregularDivisor: irregularDivisorReducer,
  irregularType: irregularTypeReducer,
})
