import { Actions as EncryptionActions } from '../../encryption'
import * as Actions from './expenses.actions'

export const decryptReceipts = EncryptionActions.decryptAction({
  actionCreator: Actions.updateReceipts,
  fields: {
    shop: true,
  },
})

export const decryptReceiptItems = EncryptionActions.decryptAction({
  actionCreator: Actions.updateReceiptItems,
  fields: {
    description: true,
  },
  numericFields: {
    value: true,
  },
})
