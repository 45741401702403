import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownProps, Label } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'

import * as Actions from '../../../expenses.actions'
import { createItemFilterSelector } from '../../../expenses.selectors'
import { useDropdownCategories } from '../../../../../hooks/use-dropdown-categories'

export const CategoryFilter = () => {
  const dispatch = useDispatch()
  const categories = useDropdownCategories()
  const valueSelector = useMemo(() => createItemFilterSelector('categoryId'), [])
  const value = useSelector(valueSelector)

  const handleChange = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const value = (data.value as string[]) ?? []
    dispatch(Actions.setItemFilter({ field: 'categoryId', value }))
  }

  return (
    <>
      <Label>
        <FormattedMessage id="expenses.filters.category" />
      </Label>
      <Dropdown
        clearable
        fluid
        multiple
        options={categories}
        onChange={handleChange}
        search
        selection
        selectOnBlur
        value={value}
      />
    </>
  )
}
