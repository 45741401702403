import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { Selectors as SettingsSelectors } from '../components/settings'
import { Selectors as CategorySelectors } from '../components/categories'

export const useDropdownCategories = () => {
  const intl = useIntl()
  const irregularType = useSelector(SettingsSelectors.irregularType)
  const categoriesSelector = useMemo(
    () =>
      CategorySelectors.createDropdownCategoriesSelector(
        intl.formatMessage({ id: `budget.${irregularType}.category` }),
      ),
    [intl, irregularType],
  )

  return useSelector(categoriesSelector)
}
