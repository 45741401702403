import React, { FC } from 'react'
import { Button, ButtonGroup, Header, Segment } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import * as Actions from '../../expenses.actions'
import { Sorting as SortingType } from '../../expenses.actions'
import styles from './sorting.module.css'
import { appliedSorting } from '../../expenses.selectors'

type SortingButtonProps = {
  field: SortingType['field']
  sort: SortingType['direction']
  defaultSorting: 'asc' | 'desc'
}

const SortingButton: FC<SortingButtonProps> = ({ field, sort, defaultSorting }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const direction = sort === 'asc' ? 'sort up' : sort === 'desc' ? 'sort down' : 'sort'

  return (
    <ButtonGroup>
      <Button
        icon="times"
        disabled={sort === 'none'}
        color={sort !== 'none' ? 'grey' : undefined}
        onClick={() => dispatch(Actions.setSortByField({ field, direction: 'none' }))}
      />
      <Button
        content={intl.formatMessage({ id: `expenses.sorting.${field}` })}
        color={sort !== 'none' ? 'grey' : undefined}
        icon={direction}
        labelPosition="right"
        onClick={() =>
          dispatch(
            Actions.setSortByField({
              field,
              direction: sort === 'none' ? defaultSorting : sort === 'asc' ? 'desc' : 'asc',
            }),
          )
        }
      />
    </ButtonGroup>
  )
}

export const Sorting = () => {
  const intl = useIntl()
  const sortBy = useSelector(appliedSorting)

  return (
    <Segment className={styles.main} attached="bottom">
      <div className={styles.header}>
        <Header as="h4" className={styles.headerContent}>
          <FormattedMessage id="expenses.sorting.title" />
        </Header>
        <Button className={styles.headerButton} content={intl.formatMessage({ id: 'expenses.sorting.reset' })} />
      </div>
      <div className={styles.buttons}>
        <SortingButton field="day" sort={sortBy['day']} defaultSorting="desc" />
        <SortingButton field="shop" sort={sortBy['shop']} defaultSorting="asc" />
      </div>
    </Segment>
  )
}
