import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Header, Loader } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isEmpty, mapObjIndexed, pipe, values } from 'ramda'

import { anyFiltersApplied, expensesByMonthReceipts, expensesLoading } from '../../../expenses/expenses.selectors'
import { SavedExpense } from '../../../expenses/components/expense'
import { Receipt } from '../../../expenses/receipt.types'

import styles from './expenses-list.module.css'

export const ExpensesList = () => {
  const intl = useIntl()
  const receiptsByMonth = useSelector(expensesByMonthReceipts)
  const loading = useSelector(expensesLoading)
  const hasFilters = useSelector(anyFiltersApplied)

  return (
    <Fragment>
      {loading && (
        <Divider horizontal>
          <Header as="h6">
            <Loader active inline className={styles.dividerLoader} size="tiny" />
            <FormattedMessage id="expenses.loading-items" />
          </Header>
        </Divider>
      )}
      {pipe(
        mapObjIndexed<Receipt[], JSX.Element, string>((receipts, month) => (
          <Fragment key={month}>
            <Header as="h5">
              <FormattedMessage
                id="expenses.header"
                values={{
                  month: intl.formatMessage({ id: `month-${month}` }),
                  year: '',
                }}
              />
            </Header>
            {receipts.map((receipt) => (
              <SavedExpense key={receipt.id} editable={false} receipt={receipt} />
            ))}
          </Fragment>
        )),
        values,
      )(receiptsByMonth)}
      {!loading && isEmpty(receiptsByMonth) && (
        <p>
          <FormattedMessage id={hasFilters ? 'yearly-expenses.no-results' : 'yearly-expenses.select-categories'} />
        </p>
      )}
    </Fragment>
  )
}
