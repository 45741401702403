import { Selectors as RouteSelectors } from './index'
import { AppState } from '../app.store'

export class Referrer {
  static setReferrer(state: AppState) {
    const type = RouteSelectors.location(state)
    const payload = RouteSelectors.payload(state)
    localStorage.setItem('referrer', JSON.stringify({ type, payload }))
  }

  static getReferrer() {
    const referrer = window.localStorage.getItem('referrer')
    if (referrer) {
      window.localStorage.removeItem('referrer')
      return JSON.parse(referrer)
    }

    return null
  }
}
