import { combineEpics, Epic, StateObservable } from 'redux-observable'
import { isActionOf } from 'typesafe-actions'
import { of } from 'rxjs'
import { filter, map, mergeAll, mergeMap } from 'rxjs/operators'
import { flatten, isEmpty, join, map as mapValues, mapObjIndexed, pipe, reject, values } from 'ramda'

import { AppAction } from '../../app.actions'
import { AppState } from '../../app.store'
import { AvailableRoutes, Selectors as RouteSelectors } from '../../routes'
import * as ExpensesActions from '../expenses/expenses.actions'
import * as ExpensesSelectors from '../expenses/expenses.selectors'
import { ConnectionService } from '../../connection.service'

type FiltersKey = ExpensesActions.Filter['field'] | ExpensesActions.ItemFilter['field']
type FiltersValue = ExpensesActions.Filter['value'] | ExpensesActions.ItemFilter['value']

const mapFilters = (state$: StateObservable<AppState>) =>
  map(() => {
    const filters = {
      ...ExpensesSelectors.appliedFilters(state$.value),
      ...ExpensesSelectors.appliedItemFilters(state$.value),
    }

    return pipe(
      mapObjIndexed<FiltersValue, [FiltersKey, FiltersValue], FiltersKey>((value, key) => [key, value]),
      values,
      reject(([, value]) => isEmpty(value)),
      mapValues(([key, value]) =>
        Array.isArray(value) ? value.map((subValue) => `${key}[]=${subValue}`) : `${key}=${value}`,
      ),
      flatten,
      join('&'),
    )(filters)
  })

const loadEntriesEpic: Epic<AppAction, AppAction, AppState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([ExpensesActions.setFilter, ExpensesActions.setItemFilter])),
    filter(() => RouteSelectors.location(state$.value) === AvailableRoutes.EXPENSES_YEAR),
    mapFilters(state$),
    filter((filters) => !isEmpty(filters)),
    map((filters) => {
      const budget = RouteSelectors.budget(state$.value)
      const year = RouteSelectors.year(state$.value)

      return `${process.env.REACT_APP_API_URL}/v2/budgets/${budget}/${year}/receipts?${filters}`
    }),
    mergeMap((url) =>
      of(
        Promise.resolve(ExpensesActions.loadingData()),
        ConnectionService.loadFromCache(url, ExpensesActions.loadReceiptsFromApi),
        ConnectionService.fetchFromNetwork(url, ExpensesActions.loadReceiptsFromApi),
      ),
    ),
    mergeAll(),
  )

const clearEntriesEpic: Epic<AppAction, AppAction, AppState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([ExpensesActions.setFilter, ExpensesActions.setItemFilter])),
    filter(() => RouteSelectors.location(state$.value) === AvailableRoutes.EXPENSES_YEAR),
    mapFilters(state$),
    filter((filters) => isEmpty(filters)),
    map(() => [
      ExpensesActions.updateReceipts({ source: 'network', value: [] }),
      ExpensesActions.updateReceiptItems({ source: 'network', value: [] }),
    ]),
    mergeAll(),
  )

export const yearlyExpensesEpic = combineEpics(loadEntriesEpic, clearEntriesEpic)
