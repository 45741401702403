import React, { FC } from 'react'
import { Button, Grid, GridColumn, Header, Segment } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import * as Actions from '../../expenses.actions'
import { CategoryFilter } from './components/category-filter'
import { DayFilter } from './components/day-filter'

import styles from './filters.module.css'

type FiltersProps = {
  hideCategory?: boolean
  hideDay?: boolean
  hideResetButton?: boolean
}

export const Filters: FC<FiltersProps> = ({ hideCategory = false, hideDay = false, hideResetButton = false }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const resetFilters = () => dispatch(Actions.resetFilters())

  if (hideCategory && hideDay) {
    return null
  }

  return (
    <Segment className={styles.main} attached="bottom">
      <div className={styles.header}>
        <Header as="h4" className={styles.headerContent}>
          <FormattedMessage id="expenses.filters.title" />
        </Header>
        {!hideResetButton && (
          <Button
            className={styles.headerButton}
            content={intl.formatMessage({ id: 'expenses.filters.reset' })}
            onClick={resetFilters}
          />
        )}
      </div>
      <Grid columns={hideCategory || hideDay ? 1 : 2}>
        {!hideCategory && (
          <GridColumn>
            <CategoryFilter />
          </GridColumn>
        )}
        {!hideDay && (
          <GridColumn>
            <DayFilter />
          </GridColumn>
        )}
      </Grid>
    </Segment>
  )
}
