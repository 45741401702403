import { combineEpics, Epic, ofType } from 'redux-observable'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { AppAction } from '../../app.actions'
import { AppState } from '../../app.store'
import { AvailableRoutes, Route, Selectors as RouteSelectors } from '../../routes'
import * as PageActions from '../page/page.actions'
import * as Actions from './settings.actions'
import * as Selectors from './settings.selectors'

const loadPageEpic: Epic<AppAction, AppAction, AppState> = (action$, state$) =>
  action$.pipe(
    ofType<AppAction, Route>(
      AvailableRoutes.BUDGET_MONTH_ENTRIES,
      AvailableRoutes.BUDGET_IRREGULAR,
      AvailableRoutes.EXPENSES_MONTH,
      AvailableRoutes.EXPENSES_YEAR,
    ),
    map(() => RouteSelectors.year(state$.value)),
    distinctUntilChanged(),
    map(() => Actions.loadBudgetSettings(Selectors.currentBudgetYear(state$.value))),
  )

const updateYearsEpic: Epic<AppAction, AppAction, AppState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(PageActions.updateBudgetYears)),
    map(() => RouteSelectors.year(state$.value)),
    distinctUntilChanged(),
    map(() => Actions.loadBudgetSettings(Selectors.currentBudgetYear(state$.value))),
  )

export const settingsEpic = combineEpics(loadPageEpic, updateYearsEpic)
