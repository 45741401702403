import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Grid, GridColumn, Header, Segment } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames'

import * as Actions from '../../expenses/expenses.actions'
import { MonthList } from '../../expenses/components/month-list'
import { Selectors as RouteSelectors } from '../../../routes'
import { ExpensesList } from './expenses-list'
import { MessageList } from '../../message-list'
import { allExpensesExpandedSelector, expensesLoading } from '../../expenses/expenses.selectors'
import { Filters } from '../../expenses/components/filters/filters'

import styles from './yearly-expenses.module.css'

export const YearlyExpenses = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const year = useSelector(RouteSelectors.year)
  const loading = useSelector(expensesLoading)

  const expanded = useSelector(allExpensesExpandedSelector)
  const toggleExpandAll = () => {
    dispatch(expanded ? Actions.contractAllReceipts() : Actions.expandAllReceipts())
  }

  return (
    <Fragment>
      <Helmet>
        <title>{intl.formatMessage({ id: 'yearly-expenses.title' })}</title>
      </Helmet>
      <Grid className={styles.container}>
        <GridColumn mobile={16} tablet={16} computer={3}>
          <MonthList>{loading && <Segment basic loading size="tiny" className={styles.inlineLoader} />}</MonthList>
        </GridColumn>
        <GridColumn mobile={16} tablet={16} computer={13}>
          <Segment attached="top" className={cx(styles.computerHeader, styles.mainHeader)} color="grey">
            <Header as="h3" className={styles.mainHeaderContent}>
              <FormattedMessage id="yearly-expenses.header" values={{ year }} />
              {loading && <Segment basic loading size="mini" floated="right" />}
            </Header>
            <ButtonGroup className={styles.mainHeaderButtons} disabled={loading}>
              <Button
                disabled={loading}
                icon={expanded ? 'compress' : 'expand'}
                onClick={toggleExpandAll}
                title={intl.formatMessage({
                  id: expanded ? 'expenses.contract-all' : 'expenses.expand-all',
                })}
              />
            </ButtonGroup>
          </Segment>
          <Filters hideDay hideResetButton />
          <MessageList />
          <ExpensesList />
        </GridColumn>
      </Grid>
    </Fragment>
  )
}
