import { actionToPath, ReceivedAction, redirect, RoutesMap, StateGetter } from 'redux-first-router'
import { Dispatch } from 'redux'
import { AppState } from '../app.store'
import { budgetParams } from './routes.selectors'
import { AvailableRoutes } from './routes.types'
import { Authenticator } from '../app.auth'
import { Referrer } from './referrer'

const redirectIfLoggedIn = (dispatch: Dispatch) => {
  if (Authenticator.isLoggedIn()) {
    const referrer = Referrer.getReferrer()
    if (referrer) {
      dispatch(redirect(referrer))
    } else {
      dispatch(
        redirect({
          type: AvailableRoutes.HOME,
        }),
      )
    }
  }
}

const redirectToBudgetMonth = (dispatch: Dispatch, getState: StateGetter) => {
  const state: AppState = getState()

  dispatch(
    redirect({
      type: AvailableRoutes.BUDGET_MONTH_ENTRIES,
      payload: budgetParams(state),
    }),
  )
}

const redirectToMonthExpenses = (dispatch: Dispatch, getState: StateGetter) => {
  const state: AppState = getState()

  dispatch(
    redirect({
      type: AvailableRoutes.EXPENSES_MONTH,
      payload: budgetParams(state),
    }),
  )
}

export const routes: RoutesMap<{}, AppState> = {
  [AvailableRoutes.HOME]: '/',
  [AvailableRoutes.LOGIN]: {
    path: '/login',
    thunk: redirectIfLoggedIn,
  },
  [AvailableRoutes.EXPENSES]: {
    path: '/expenses/:budget/:year',
    thunk: redirectToMonthExpenses,
  },
  [AvailableRoutes.EXPENSES_MONTH]: {
    path: '/expenses/:budget/:year/month/:month',
    coerceNumbers: true,
  },
  [AvailableRoutes.EXPENSES_YEAR]: {
    path: '/expenses/:budget/:year/whole-year',
    coerceNumbers: true,
  },
  [AvailableRoutes.BUDGET]: {
    path: '/budget/:budget',
    thunk: redirectToBudgetMonth,
  },
  [AvailableRoutes.BUDGET_ENTRIES]: {
    path: '/budget/:budget/:year',
    thunk: redirectToBudgetMonth,
  },
  [AvailableRoutes.BUDGET_MONTH_ENTRIES]: {
    path: '/budget/:budget/:year/month/:month',
    coerceNumbers: true,
  },
  [AvailableRoutes.BUDGET_IRREGULAR]: {
    path: '/budget/:budget/:year/irregular',
    coerceNumbers: true,
  },
}

export const generatePath = (action: ReceivedAction) => actionToPath(action, routes)
export const generateUrl = (action: ReceivedAction) => `${process.env.REACT_APP_URL}${generatePath(action)}`
export const generateLoginUrl = () => generateUrl({ type: AvailableRoutes.LOGIN, payload: {} })
