import React, { Fragment } from 'react'
import { Container, Header } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import Helmet from 'react-helmet'

import { MessageList } from '../../message-list'

import styles from './login.module.css'

export const Login = () => (
  <Fragment>
    <Helmet>
      <title>Login - Simply Budget Receipts</title>
    </Helmet>
    <MessageList />
    <Container text className={styles.container}>
      <Header as="h1">
        <FormattedMessage id="login.header" />
      </Header>
    </Container>
  </Fragment>
)
