import {
  ascend,
  descend,
  equals,
  flatten,
  groupBy,
  mapObjIndexed,
  pipe,
  prop,
  propEq,
  sortWith,
  sum,
  values,
} from 'ramda'
import { createSelector } from 'reselect'

import { AppState } from '../../app.store'
import { Receipt, ReceiptItem } from './receipt.types'
import { Filter, ItemFilter, Sorting } from './expenses.actions'
import { EMPTY_FILTERS, EMPTY_ITEM_FILTERS } from './expenses.reducer'

const allReceipts = (state: AppState) => state.expenses.receipts
const allReceiptItems = (state: AppState): { [key: string]: ReceiptItem[] } => state.expenses.items

export const appliedFilters = (state: AppState) => state.expenses.filters
export const appliedItemFilters = (state: AppState) => state.expenses.itemFilters
export const appliedSorting = (state: AppState) => state.expenses.sorting
export const expensesLoading = (state: AppState) => state.expenses.loading

export const anyFiltersApplied = createSelector(
  [appliedFilters, appliedItemFilters],
  (filters, itemFilters) => !equals(EMPTY_FILTERS, filters) || !equals(EMPTY_ITEM_FILTERS, itemFilters),
)

const checkFilters = <T extends string>(
  filters: Record<T, string | number | (string | number)[]>,
  item: Record<T, string | number>,
) =>
  values(
    mapObjIndexed((value, field) => {
      if (Array.isArray(value)) {
        return value.length === 0 || value.includes(item[field] || '')
      }

      return !value || value === item[field]
    }, filters),
  ).every(Boolean)

// TODO: Can I do it better?
const parseDay = (value: string | number) => parseInt(value.toString(), 10)
const sortedExpensesReceipts = createSelector([allReceipts, appliedSorting], (receipts, sorting) => {
  const query: any[] = []

  if (sorting.day !== 'none') {
    const fieldProp = pipe(prop<Sorting['field'], string | number>('day'), parseDay)
    query.push(sorting.day === 'asc' ? ascend(fieldProp) : descend(fieldProp))
  }
  if (sorting.shop !== 'none') {
    const fieldProp = prop<Sorting['field'], string | number>('shop')
    query.push(sorting.shop === 'asc' ? ascend(fieldProp) : descend(fieldProp))
  }
  query.push(ascend<Receipt>(prop('id')))

  return sortWith<Receipt>(query)(receipts)
})

export const expensesReceipts = createSelector(
  [sortedExpensesReceipts, allReceiptItems, appliedFilters, appliedItemFilters],
  (receipts, receiptItems, filters, itemFilters) =>
    receipts.filter(
      (receipt) =>
        checkFilters(filters, receipt) && receiptItems[receipt.id]?.some((item) => checkFilters(itemFilters, item)),
    ),
)

export const expensesByMonthReceipts = createSelector(
  [expensesReceipts],
  groupBy<Receipt, string>((receipt) => receipt.month.toString()),
)

export const createReceiptSelector = (id: number) =>
  createSelector([expensesReceipts], (receipts) => receipts.find(propEq('id', id)))

export const createReceiptItemsSelector = (id: number) =>
  createSelector([allReceiptItems, appliedItemFilters], (items, itemFilters) =>
    (items[id] || []).filter((item) => checkFilters(itemFilters, item)),
  )

export const createReceiptItemSelector = (receiptId: number, itemId: number) =>
  createSelector([createReceiptItemsSelector(receiptId)], (items) => items.find(propEq('id', itemId)))

export const expensesAllItems = createSelector([allReceiptItems], (items) => flatten(values(items)))

const createCategoryItemsSelector = (categoryId: number) =>
  createSelector([expensesAllItems], (items) => items.filter(propEq('categoryId', categoryId)))

export const createCategorySpentSelector = (categoryId: number) =>
  createSelector([createCategoryItemsSelector(categoryId)], (items) => sum(items.map(prop('value'))))

export const allExpensesExpandedSelector = createSelector([expensesReceipts], (items) =>
  items.every((item) => item.expanded),
)

export const createFilterSelector = (field: Filter['field']) =>
  createSelector([appliedFilters], (filters) => filters[field])

export const createItemFilterSelector = (field: ItemFilter['field']) =>
  createSelector([appliedItemFilters], (itemFilters) => itemFilters[field])
