import { createSelector } from 'reselect'
import { propEq } from 'ramda'

import { AppState } from '../../app.store'
import { year } from '../../routes/routes.selectors'
import { budgetYears } from '../page/page.selectors'
import { BudgetYear } from '../page/budget.types'

export const currency = (state: AppState) => state.settings.currency
export const irregularDivisor = (state: AppState) => state.settings.irregularDivisor
export const irregularType = (state: AppState) => state.settings.irregularType
export const isIrregularExpense = (state: AppState) => state.settings.irregularType === 'irregular'

export const currentBudgetYear = createSelector(
  [budgetYears, year],
  (years, year) =>
    years.find(propEq('year', year)) ||
    ({
      year,
      irregularType: 'irregular',
    } as BudgetYear),
)
