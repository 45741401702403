import { combineEpics, Epic, ofType } from 'redux-observable'
import { filter, ignoreElements, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { Auth0Client } from '@auth0/auth0-spa-js'

import { AppAction } from '../app.actions'
import { AppState } from '../app.store'
import * as Actions from './auth.actions'
import * as SystemActions from '../system.actions'
import { AvailableRoutes, generateLoginUrl, Route, RouteAction } from '../routes'
import { Authenticator } from '../app.auth'
import { Encryption } from '../encryption/encryption'
import { pages } from '../routes/pages'
import { redirect } from 'redux-first-router'
import { Referrer } from '../routes/referrer'

const auth0 = new Auth0Client({
  domain: 'megawebmaster.eu.auth0.com',
  clientId: 'PqsSsHBLYS3MDS2FXthxnouqES3Amiu9',
  authorizationParams: {
    redirect_uri: process.env.REACT_APP_URL,
    scope: 'openid profile email',
  },
})

const pagesRequiringLogin = Object.keys(pages).filter((page) => pages[page as Route].requiresLogin) as Route[]

const pageLoadEpic: Epic<AppAction, AppAction, AppState> = (action$, state$) =>
  action$.pipe(
    // @ts-ignore
    ofType<AppAction, Route>(...pagesRequiringLogin),
    mergeMap(async () => {
      try {
        const authentication = await auth0.getTokenSilently({ detailedResponse: true })
        Authenticator.setToken(authentication.id_token, authentication.expires_in)
        return Actions.loggedIn()
      } catch (e: any) {
        if (e.error === 'login_required') {
          Referrer.setReferrer(state$.value)
          await auth0.loginWithRedirect({
            authorizationParams: {
              redirect_uri: generateLoginUrl(),
            },
          })
          return SystemActions.noop()
        } else {
          return Actions.loginError(`login.error.${e.error}`)
        }
      }
    }),
  )

const loginEpic: Epic<AppAction, AppAction, AppState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(Actions.login)),
    tap(async () => {
      try {
        const authentication = await auth0.getTokenSilently({ detailedResponse: true })
        Authenticator.setToken(authentication.id_token, authentication.expires_in)
      } catch (e) {
        Referrer.setReferrer(state$.value)
        await auth0.loginWithRedirect({
          authorizationParams: {
            redirect_uri: generateLoginUrl(),
          },
        })
      }
    }),
    ignoreElements(),
  )

const parseLoginEpic: Epic<AppAction, AppAction, AppState> = (action$, state$) =>
  action$.pipe(
    // @ts-ignore
    ofType<AppAction, Route>(AvailableRoutes.LOGIN),
    map(() => state$.value.location.query),
    filter(Boolean),
    switchMap(async () => {
      try {
        await auth0.handleRedirectCallback()
        const authentication = await auth0.getTokenSilently({ detailedResponse: true })
        Authenticator.setToken(authentication.id_token, authentication.expires_in)
        return Actions.loggedIn()
      } catch (e: any) {
        return Actions.loginError(e.message)
      }
    }),
  )

const redirectAfterLoginEpic: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isActionOf(Actions.loggedIn)),
    map(() => Referrer.getReferrer()),
    filter(Boolean),
    map((referrer) => redirect(referrer) as RouteAction),
  )

const logoutEpic: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isActionOf(Actions.logout)),
    tap(() => {
      Authenticator.removeToken()
      Encryption.removePasswords()
      auth0.logout()
    }),
    ignoreElements(),
  )

export const authEpic = combineEpics(pageLoadEpic, loginEpic, parseLoginEpic, redirectAfterLoginEpic, logoutEpic)
